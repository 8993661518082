// Importing stores into this file proved not be effective, because this file is loaded before the stores, making referencing values in the store as undefined

/* Administrator Dashboard */
export const getEligibleAgencies = '/api/agencies/eligible_agencies'
export const getSwitchAgency = '/api/agencies/switch_agency'
export const postDeactivate = 'deactivate_caregivers'
export const postDelete = 'delete_caregivers'
export const postMemberTags = '/api/tags'
export const postReactivate = 'reactivate_caregivers'
export const postResendWelcomeMessage = '/resend_welcome_message'
export const postSendPasswordDetails = '/send_password_reset_details'

/* Content Search Logs */
export const postContentSearchLog = '/api/agencies/:agencyId/content_search_log'

/* Course Catalog */
export const getCourseCatalogList = '/api/agencies/:agencyId/course_catalog'
export const getRecommendationsCatalogList = '/api/agencies/:agencyId/recommendations_catalog'

/* Deactivated Team Members */
export const getDeactivatedMemberList = '/api/caregivers/inactive_caregivers'

/* Courses */
export const getAvailableCourses = '/agencies/:agencyId/available_courses'
export const getCoursesOffline = '/agencies/:agencyId/offline_courses'
export const postUpdateCourse = '/api/courses/update_course'
export const printCompletionCertificates = '/agencies/:agencyId/print_completion_certificate'
export const printComplianceReport = '/agencies/:agencyId/print_compliance_report'
export const putAddCourses = '/agencies/:agencyId/caregivers/add_class'
export const putWaiveCourses = '/agencies/:agencyId/caregivers/waive_class'

/* Curriculum Management */
export const postCurriculumTrackCopy = '/api/curriculum_tracks/copy_to_all_agencies'
export const postCurriculumTrackCreate = '/api/curriculum_tracks/create'
export const postCurriculumTrackDelete = '/api/curriculum_tracks/delete/:agencyId'
export const postCurriculumTrackDuplicate = '/api/curriculum_tracks/duplicate'
export const putCurriculumAdvancedOptions = '/api/curriculum/curriculum_advanced_options'
export const putCurriculumTrackEdit = '/api/curriculum_tracks/edit'

/* Franchisor */
export const postFranchisorAgencyStats = 'api/agencies/stats'

/* Groups */
export const putGroupAssignment = '/api/caregiver_groups/:agencyId/assignment'

/* Member Dashboard */
export const getAgencyListing = '/api/users/trainings'
export const getCompletionCertificates = '/api/caregivers/:memberId/certificates'
export const getEnrolledCourses = '/api/caregivers/autoenrolled_courses'

/* Members */
export const addNewMember = '/agencies/:agencyId/caregivers/new'

/* Packages */
export const getCurriculumPackages = '/curriculum_packages'
export const getPackageFolderList = '/content_package_folder_list'
export const getPackageFolders = '/content_package_folders'
export const getPackages = '/packages'
export const putMovePackageAmongFolder = '/packages/move_package_among_folders'

/* Preferred Language */
export const preferredLanguage = '/preferred_language'

/* Self Enrollment */
export const getAvailableClassList = '/api/available_class_lists'
export const postSelfEnrollIntoGoal = '/api/self_enrollments'

/* Sign Up */
export const getEmailAvailable = '/api/users/email_available'
export const postFreeTrialSignup = '/api/free_trial_signups'
export const postFreeTrialSetup = '/api/free_trial_setups'

/* Specialized Certifications */
export const disableCertification = '/training/specialized_certifications/delete'
export const getAvailableCertifications = '/api/curriculum/specialized_certifications/:agencyId'
export const getCertifications = '/training/specialized_certifications.json'
export const postCreateCertification = '/training/specialized_certifications/new'
export const postDisableCertification = '/api/courses/disable_course'
export const postReleaseCertification = '/training/specialized_certifications/release'
export const postReleaseCertificationToAgency = '/training/specialized_certifications/release_certification'
export const putRenameCertification = '/api/curriculum/specialized_certifications'
export const putUnreleaseCertification = '/api/curriculum/specialized_certifications/unrelease'

/* Training Curriculums */
export const deleteTrainingBundleCurriculum = '/training/curriculums/delete'
export const getTrainingCurriculumsList = '/training/curriculums.json'
export const postCreateCurriculum = '/training/curriculums/new'
export const postReleaseCurriculum = '/training/curriculums/release'
export const putChangeCurriculumTrack = '/api/agencies/:agencyId/training_groups/:groupId/training_programs/:curriculumTrackId'

/* Training Logs */
export const postTrainingLogForAgency = '/api/training_logs/agency_report'
export const postTrainingLogForFranchisor = '/api/training_logs/franchisor_report'

/* Video Library */
export const getAvailableVideoList = '/api/agencies/:agencyId/videos'
export const videoWatchProgress = '/api/video_watch_progresses'

/* Route builder function */
/* Takes a template (e.g. agencies/:agencyId/videos) and params {agencyId: 1} to build the url */
type RouteParams = Record<string, number | string>
type QueryParams = Record<string, number | string> | number | object | string

export const apiRouteBuilder = (
	template: string,
	params: RouteParams,
	query?: QueryParams
): string => {
	let url = Object.entries(params)
		.reduce((route, [key, value]) => route.replace(`:${key}`, `${value}`), template)

	if (query) {
		url += `?${Object.entries(query)
			.map(([key, value]) => `${key}=${value}`)
			.join('&')}`
	}

	return url
}
