import { NotificationService } from '@careacademy/ca-design-system'
import { format } from 'date-fns'
import Vue from 'vue'
import type { Route } from 'vue-router'

import type { BulkAction } from '@/javascript/components/bulk_actions_dialog'
import type {
	Agency,
	AgencyBackOffice,
	AgencyInformation,
	CurrentUser,
	EmittedEvent,
	Language,
} from '@/javascript/interfaces'
import type { CAMember } from '@/javascript/interfaces'
import { standardDateFormat } from '@/javascript/lib/validate_date/process-date-string'
import { COUNTDOWN, DELAY } from '@/javascript/vuejs/helpers/_constants'
import AdministratorStore from '@/javascript/vuejs/stores/AdministratorStore'
import CurriculumsStore from '@/javascript/vuejs/stores/CurriculumsStore'
import FranchisorStore from '@/javascript/vuejs/stores/FranchisorStore'
import GroupsStore from '@/javascript/vuejs/stores/GroupsStore'
import MemberTrainingStore from '@/javascript/vuejs/stores/MemberTrainingStore'
import TeamStore from '@/javascript/vuejs/stores/TeamStore'
import { getAgencyId } from '@/javascript/vuejs/utils/getAgencyId'

import type { MemberAction } from '../services/administratorDashboard/interfaces'
import api from '../services/api'
import type { Member } from '../services/members/interfaces'
import { getAgencyTeam } from '../services/team'
import type { State } from '../services/trainingCurriculums/interfaces'
import { getTrialDetails } from '../services/trialSeatUpgrade'
import { getIdFromUrl } from '../utils/getIdFromUrl'

export const state = Vue.observable({
	agency: {} as Agency,
	agencyBackOffice: {
		backOfficeIntegrationEnabled: false,
		backOfficeIntegrationProviderName: '',
		marketingProductName: '',
	} as AgencyBackOffice,
	agencyCreatedAt: '',
	agencyId: null as number | null,
	agencyInfo: {} as AgencyInformation,
	agencyMembers: [] as Member[],
	bulkAction: {
		action: '' as MemberAction,
		actionDescriptionText: '',
		actionText: '',
		additionalMembersText: '',
		bodyText: '',
		memberIds: [] as number[],
		membersText: '',
	} as BulkAction,
	checkedOnAnotherPage: false,
	currentDate: '',
	currentDateString: '',
	currentPage: 1,
	currentUrl: null as string | null,
	currentUser: null as CurrentUser | null,
	currentView: '',
	displayText: {
		agencies: 'Agencies',
		agency: 'agency',
		dashboard: 'dashboard',
		member: 'caregiver',
	},
	eligibleAgenciesUrl: '/api/agencies/eligible_agencies',
	emittedEvent: '' as EmittedEvent,
	franchisorSSOPath: null as string | null,
	inFreeTrial: undefined as boolean | undefined,
	includeGroupMembersLink: false,
	isAdvanceReporting: false,
	isBillingAdmin: false,
	isEcommerceEnabled: '',
	isInTeamFlow: false,
	isMemberOnly: false,
	isMobileView: false,
	isTeamFlowEnabled: false,
	loadingAgencyInfo: false,
	page: '',
	previousRoute: null as Route | null,
	previousRouteUrl: null as string | null,
	routeError: '',
	selectAllChecked: false,
	selectAllCheckedOnPage: {} as { [page: number]: boolean },
	selectedCATableMembers: [] as CAMember[],
	selectedCheckboxNotOnPage: false,
	selectedLanguage: '' as Language,
	showAddAllToOrganizations: false,
	showPopover: {
		id: null as number | null,
		name: '' as PopoverOptions,
	},
	showTrainingDashboard: true,
	simpleHeaderLayout: false,
	someSelectAllCheckboxIsTrue: false,
	states: [] as State[],
	teamMemberText: 'caregiver',
	twilioSuccessCode: '',
	twilioUserErrorMessages: {} as { [key: string]: string },
	userId: '',
})

const store: any = {
	get agency(): Agency {
		return state.agency
	},
	set agency(value) {
		state.agency = value
	},

	get agencyBackOffice(): AgencyBackOffice {
		return state.agencyBackOffice
	},
	set agencyBackOffice(value) {
		state.agencyBackOffice = value
	},

	get agencyCreatedAt(): string {
		return state.agencyCreatedAt
	},
	set agencyCreatedAt(value) {
		state.agencyCreatedAt = value
	},

	get agencyId(): number | null {
		return getAgencyId()
	},

	get agencyInfo(): AgencyInformation {
		return state.agencyInfo
	},
	set agencyInfo(value) {
		state.agencyInfo = value
	},

	get agencyMembers(): Member[] {
		return state.agencyMembers
	},
	set agencyMembers(value) {
		state.agencyMembers = value
	},

	get bulkAction(): BulkAction {
		return state.bulkAction
	},
	set bulkAction(value) {
		state.bulkAction = value
	},

	get checkedOnAnotherPage(): boolean {
		return state.someSelectAllCheckboxIsTrue && state.selectedCheckboxNotOnPage
	},

	get currentDate(): string {
		return format(new Date(), 'EEE, dd MMMM yyyy hh:mm:ss xxxx')
	},

	get currentDateString(): string {
		return standardDateFormat(new Date())
	},

	get currentPage(): number {
		return state.currentPage
	},
	set currentPage(value) {
		state.currentPage = value
	},

	get currentUser(): CurrentUser | null {
		return state.currentUser
	},
	set currentUser(value) {
		state.currentUser = value
	},

	get currentUrl(): string | null {
		return state.currentUrl
	},
	set currentUrl(value) {
		state.currentUrl = value
	},

	get currentView(): string {
		return state.currentView
	},
	set currentView(value) {
		state.currentView = value
	},

	get displayText(): { [key: string]: string } {
		switch (true) {
			case store.isTeamFlowEnabled:
			case FranchisorStore.isTeamFlowEnabled:
				return {
					agencies: 'Organizations',
					agency: 'organization',
					dashboard: 'insights',
					member: FranchisorStore.isTeamFlowEnabled && !state.agencyId ? 'member' : 'team member',
				}
			default:
				return {
					agencies: 'Agencies',
					agency: 'agency',
					dashboard: 'dashboard',
					member: 'caregiver',
				}
		}
	},

	get eligibleAgenciesUrl(): string {
		return state.eligibleAgenciesUrl
	},

	get emittedEvent(): EmittedEvent {
		return state.emittedEvent
	},
	set emittedEvent(value) {
		state.emittedEvent = ''
		state.emittedEvent = value
	},

	get franchisorSSOPath(): string | null {
		return state.franchisorSSOPath
	},
	set franchisorSSOPath(value) {
		state.franchisorSSOPath = value
	},

	get inFreeTrial(): boolean | undefined {
		return (
			state.agency?.status === 'free_trial' ||
			state.currentUser?.agency?.status === 'free_trial' ||
			state.agencyInfo?.is_in_free_trial
		)
	},

	get includeGroupMembersLink(): boolean {
		return state.includeGroupMembersLink
	},
	set includeGroupMembersLink(value) {
		state.includeGroupMembersLink = value
	},

	get isAdvanceReporting(): boolean {
		return state.isAdvanceReporting
	},
	set isAdvanceReporting(value) {
		state.isAdvanceReporting = value
	},

	get isBillingAdmin(): boolean | undefined {
		return state.currentUser?.isSuper ||
			state.currentUser?.isFranchisorManager ||
			state.currentUser?.isAgencyBillingManager ||
			state.currentUser?.isAgencyOwner
	},

	get isEcommerceEnabled(): boolean {
		return !!state.agency?.plg_eccomerce_enabled_at
	},

	get isInTeamFlow(): boolean {
		return state.currentView === 'team'
	},

	get isMemberOnly(): boolean | undefined {
		return !state.currentUser?.isSuper &&
			!state.currentUser?.isAgencyBillingManager &&
			!state.currentUser?.isAgencyManager &&
			!state.currentUser?.isFranchisorManager &&
			!state.currentUser?.isMoreThanCaregiver &&
			state.currentUser?.isCaregiver
	},

	get isMobileView(): boolean {
		const element = document.querySelector('body')

		return element ? element.classList.contains('mobile-view') : false
	},

	get isTeamFlowEnabled(): boolean {
		return state.agency?.enabled_team_flow
	},

	get page(): string {
		return state.page
	},
	set page(value) {
		state.page = value
	},

	get previousRouteUrl(): string | null {
		return state.previousRouteUrl
	},
	set previousRouteUrl(value) {
		state.previousRouteUrl = value
	},

	get previousRoute(): Route | null {
		return state.previousRoute
	},
	set previousRoute(value) {
		state.previousRoute = value
	},

	get routeError(): string {
		return state.routeError
	},
	set routeError(value) {
		state.routeError = value
	},

	get selectAllChecked(): boolean {
		return state.selectAllChecked
	},
	set selectAllChecked(value) {
		state.selectAllChecked = value
	},

	get selectAllCheckedOnPage(): { [page: number]: boolean } {
		return state.selectAllCheckedOnPage
	},
	set selectAllCheckedOnPage(value) {
		state.selectAllCheckedOnPage = value
	},

	get selectedCATableMembers(): CAMember[] {
		return state.selectedCATableMembers
	},
	set selectedCATableMembers(value) {
		state.selectedCATableMembers = value
	},

	get selectedCheckboxNotOnPage(): boolean {
		return !state.selectAllCheckedOnPage[TeamStore.pagination.currentPage]
	},

	get selectedLanguage(): Language {
		return state.selectedLanguage
	},
	set selectedLanguage(value) {
		state.selectedLanguage = value
	},

	get showAddAllToOrganizations(): boolean {
		if (!state.currentUser) {
			return false
		}

		return (state.currentUser.isFranchisorManager || state.currentUser.isSuper) && !!state.currentUser.franchisor
	},

	get someSelectAllCheckboxIsTrue(): boolean {
		return Object.values(state.selectAllCheckedOnPage).some(value => value === true)
	},

	get showFranchise(): boolean {
		if (!state.currentUser) {
			return false
		}

		return state.currentUser.isFranchisorManager || state.currentUser.isSuper
	},

	get showPopover(): { id: number | null, name: PopoverOptions } {
		return state.showPopover
	},
	set showPopover(value) {
		state.showPopover = value
	},

	get showTrainingDashboard(): boolean {
		return state.showTrainingDashboard
	},
	set showTrainingDashboard(value) {
		state.showTrainingDashboard = value
	},

	get simpleHeaderLayout(): boolean {
		return state.simpleHeaderLayout
	},
	set simpleHeaderLayout(value) {
		state.simpleHeaderLayout = value
	},

	get states(): State[] {
		return [
			{
				abbreviation: 'AL',
				name: 'Alabama',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'AK',
				name: 'Alaska',
				timezone: 'Alaska',
			},
			{
				abbreviation: 'AZ',
				name: 'Arizona',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'AR',
				name: 'Arkansas',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'CA',
				name: 'California',
				timezone: 'Pacific Time (US & Canada)',
			},
			{
				abbreviation: 'CO',
				name: 'Colorado',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'CT',
				name: 'Connecticut',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'DE',
				name: 'Delaware',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'DC',
				name: 'District of Columbia',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'FL',
				name: 'Florida',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'GA',
				name: 'Georgia',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'HI',
				name: 'Hawaii',
				timezone: 'Hawaii',
			},
			{
				abbreviation: 'ID',
				name: 'Idaho',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'IL',
				name: 'Illinois',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'IN',
				name: 'Indiana',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'IA',
				name: 'Iowa',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'KS',
				name: 'Kansas',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'KY',
				name: 'Kentucky',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'LA',
				name: 'Louisiana',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'ME',
				name: 'Maine',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'MD',
				name: 'Maryland',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'MA',
				name: 'Massachusetts',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'MI',
				name: 'Michigan',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'MN',
				name: 'Minnesota',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'MS',
				name: 'Mississippi',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'MO',
				name: 'Missouri',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'MT',
				name: 'Montana',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'NE',
				name: 'Nebraska',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'NV',
				name: 'Nevada',
				timezone: 'Pacific Time (US & Canada)',
			},
			{
				abbreviation: 'NH',
				name: 'New Hampshire',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'NJ',
				name: 'New Jersey',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'NM',
				name: 'New Mexico',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'NY',
				name: 'New York',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'NC',
				name: 'North Carolina',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'ND',
				name: 'North Dakota',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'OH',
				name: 'Ohio',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'OK',
				name: 'Oklahoma',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'OR',
				name: 'Oregon',
				timezone: 'Pacific Time (US & Canada)',
			},
			{
				abbreviation: 'PA',
				name: 'Pennsylvania',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'RI',
				name: 'Rhode Island',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'SC',
				name: 'South Carolina',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'SD',
				name: 'South Dakota',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'TN',
				name: 'Tennessee',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'TX',
				name: 'Texas',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'UT',
				name: 'Utah',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'VT',
				name: 'Vermont',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'VA',
				name: 'Virginia',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'WA',
				name: 'Washington',
				timezone: 'Pacific Time (US & Canada)',
			},
			{
				abbreviation: 'WV',
				name: 'West Virginia',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'WI',
				name: 'Wisconsin',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'WY',
				name: 'Wyoming',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'PR',
				name: 'Puerto Rico',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'USVI',
				name: 'U.S. Virgin Islands',
				timezone: 'Atlantic Time (Canada)',
			},
			{
				abbreviation: 'AB',
				name: 'Alberta',
				timezone: 'Mountain Time (US & Canada)',
			},
			{
				abbreviation: 'BC',
				name: 'British Columbia',
				timezone: 'Pacific Time (US & Canada)',
			},
			{
				abbreviation: 'MB',
				name: 'Manitoba',
				timezone: 'Central Time (US & Canada)',
			},
			{
				abbreviation: 'NB',
				name: 'New Brunswick',
				timezone: 'Atlantic Time (Canada)',
			},
			{
				abbreviation: 'NL',
				name: 'Newfoundland',
				timezone: 'Newfoundland',
			},
			{
				abbreviation: 'NS',
				name: 'Nova Scotia',
				timezone: 'Atlantic Time (Canada)',
			},
			{
				abbreviation: 'ON',
				name: 'Ontario',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'PE',
				name: 'Prince Edward Island',
				timezone: 'Atlantic Time (Canada)',
			},
			{
				abbreviation: 'QC',
				name: 'Quebec',
				timezone: 'Eastern Time (US & Canada)',
			},
			{
				abbreviation: 'SK',
				name: 'Saskatchewan',
				timezone: 'Central Time (US & Canada)',
			},
		]
	},

	get teamMemberText(): string {
		return store.isTeamFlowEnabled ? 'team member' : 'caregiver'
	},

	get twilioSuccessCode(): string {
		return state.twilioSuccessCode
	},
	set twilioSuccessCode(value) {
		state.twilioSuccessCode = value
	},

	get twilioUserErrorMessages(): { [key: string]: string } {
		return state.twilioUserErrorMessages
	},
	set twilioUserErrorMessages(value) {
		state.twilioUserErrorMessages = value
	},

	get userId(): string {
		return state.userId
	},
	set userId(value) {
		state.userId = value
	},

	// Actions
	clearSelectedValues(): void {
		state.selectedCATableMembers = []
		state.selectAllCheckedOnPage = {}
		state.selectAllChecked = false
	},

	closeModal(): void {
		state.emittedEvent = ''
		state.selectedCATableMembers = []

		AdministratorStore.selectedMembers = []
		MemberTrainingStore.resetData()
		CurriculumsStore.resetData()
		GroupsStore.action = ''
		GroupsStore.selectedMemberId = undefined
	},

	closePopover(): void {
		state.showPopover = { id: null, name: '' }
	},

	countDownTimer({
		newMessage,
		response,
	}: {
		newMessage?: string,
		response?: any
	}): void {
		let countdown = COUNTDOWN
		const interval = setInterval(() => {
			countdown--
			if (newMessage) { this.onHandleSuccessMessages({ message: `${newMessage} Refreshing page in ${countdown} seconds.` }) }

			if (response) {
				this.onHandleSuccessMessages({ response: `${response.data?.success_messages} Refreshing page in ${countdown} seconds.` })
			}

			if (countdown === 0) {
				clearInterval(interval)
				window.location.reload()
			}
		}, DELAY.NORMAL)
	},

	formatSeatsUsed(seatUsed = 0): number {
		return Math.trunc(seatUsed) ?? 0
	},

	getAPIErrorMessage(error: any, defaultErrorMessage = 'An error occurred, please try again'): string[] | string {
		return error.response?.data?.message ||
			error.message ||
			error.responseJSON?.message ||
			defaultErrorMessage
	},

	getAPISuccessMessage(response: any, defaultSuccessMessage = 'Successfully completed'): string[] | string {
		return response.data?.success ||
			response.responseText ||
			response.data?.message ||
			response.message ||
			response.data?.success_messages ||
			response.success ||
			response.message ||
			response.responseJSON?.message ||
			response.data ||
			response ||
			defaultSuccessMessage
	},

	async getAgencyInfo(): Promise<void> {
		state.loadingAgencyInfo = true
		try {
			const id = getIdFromUrl()

			if (Number.isInteger(id)) {
				const response = await getTrialDetails(api, `${id}`)
				const { data } = response

				if (data.agency) {
					state.agencyInfo = {
						...state.agencyInfo,
						days_left: data.agency.days_left,
						is_expired: data.agency.is_expired,
						total_trial_days: data.agency.total_trial_days,
					}
				}
			}
		} catch (error) {
			this.onHandleErrorMessages({ error })
		} finally {
			state.loadingAgencyInfo = false
		}

	},

	// Actions
	async fillAgencyBackOffice(): Promise<void> {

		try {
			const response = await getAgencyTeam(api, getAgencyId())

			state.agencyBackOffice = {
				backOfficeIntegrationEnabled: response.agency.back_office_integration_enabled,
				backOfficeIntegrationProviderName: response.agency.back_office_integration_provider_name,
				marketingProductName: response.agency.marketing_product_name,
			}
		} catch (error: any) {
			this.onHandleErrorMessages({ error })
		}
	},

	getTwilioUserErrorMessage(errorCode: string): string {
		return state.twilioUserErrorMessages[errorCode]
	},

	hasTwilioUserError(errorCode: string): boolean {
		return state.twilioUserErrorMessages.hasOwnProperty(errorCode)
	},

	identifyPage(url: string) {
		const teamPagePattern = /^\/agencies\/\d+\/team\/?$/

		const groupMembersPagePattern = /^\/agencies\/\d+\/team\/\d+\/members\/?(\d+\/?)?$/

		if (teamPagePattern.test(url)) {
			return 'teamPage'
		} else if (groupMembersPagePattern.test(url)) {
			return 'groupMembersPage'
		} else {
			return 'unknownPage'
		}
	},

	notify({
		duration,
		message,
		type,
	}: {
		duration?: number,
		message: string | null,
		type: 'error' | 'success',
	}): void {
		if (!message) {
			return
		}

		if (type === 'error') {
			NotificationService.methods.error(message, duration)
		} else {
			NotificationService.methods.success(message, duration)
		}
	},

	onHandleErrorMessages({ duration, error }: { duration?: number, error: any }): void {
		if (!!error.name && error.name === 'SecurityError') {
			this.notify({
				duration,
				message: error.message ?? error,
				type: 'error',
			})

			return
		}

		const errorMessages = error.data?.error ??
			error.response?.data?.error ??
			error.response?.data?.errors ??
			error.response?.data?.messages ??
			error.response?.data?.message ??
			error.response?.error ??
			error.response?.errors ??
			error.data?.error_messages ??
			error.responseJSON?.message ??
			error.message ??
			error.json ??
			error ??
			'An error occurred. Please try again.'

		const message = typeof error === 'string' ? error : errorMessages

		console.error(message)

		if (typeof message === 'string') {
			this.notify({
				message: message as 'error',
				type: 'error',
			})
		} else {
			for (const element in message) {
				for (const issue of message[element]) {
					console.error(issue)
					this.notify({
						message: issue as 'error',
						type: 'error',
					})
				}
			}
		}
	},

	onHandleSuccessMessages({
		duration,
		message,
		response,
	}: {
		duration?: number,
		message?: string,
		response?: any,
	}): void {
		if (message) {
			this.notify({
				duration,
				message,
				type: 'success',
			})
		} else if (response) {
			const successes = response.data?.success ??
				response.responseText ??
				response.data?.message ??
				response.message ??
				response.data?.success_messages ??
				response.success ??
				response.message ??
				response.responseJSON?.message ??
				response.data ??
				response ??
				'Successfully completed!'

			const errorMessage = response.error

			if (typeof successes === 'string') {
				this.notify({ message: successes, type: 'success' })
			} else if (Array.isArray(successes)) {
				successes.forEach((message: string) => this.notify({
					message,
					type: 'success',
				}))
			}

			if (errorMessage) {
				this.notify({ message: errorMessage, type: 'error' })
			}
		}
	},

	onHandleTextMessageResponseError({ response }: { response?: any }): void {
		const errors = response?.data?.error_messages ?? response?.data?.error ?? response.error

		if (typeof errors === 'string') {
			this.notify({ message: errors, type: 'error' })
		} else {
			!!errors && errors.map((message: 'error') => this.notify({ message, type: 'error' }))
		}
	},

	openSendBulkMessage(): void {
		state.emittedEvent = 'send-bulk-message'
	},

	openSendGroupMessage(): void {
		state.emittedEvent = 'send-group-message'
	},

	openSendMemberMessage(): void {
		AdministratorStore.selectedMembers = []
		state.emittedEvent = 'send-member-message'
	},

	resetCAModal(): void {
		state.emittedEvent = ''
	},

	scrollToTop(scrollContainerId?: string): void {
		const scrollContainer = scrollContainerId ? document.getElementById(scrollContainerId) : undefined

		if (scrollContainer) {
			if (scrollContainer.scrollHeight > scrollContainer.clientHeight) {
				scrollContainer.scrollTo({ top: 0, behavior: 'smooth' })
			}
		} else {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		}
	},

	trimGroupMembersLink(route: string): string {
		const indexOfSearchString = route.indexOf('members')

		if (indexOfSearchString !== -1) {
			return route.substring(0, indexOfSearchString + 'members'.length)
		}

		// If the search string is not found, return the original input string
		return route
	},
}

export default store

export type PopoverOptions
	= ''
	| 'Due date'
	| 'Edit name'
